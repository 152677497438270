﻿
/*! npm.im/object-fit-images */
var objectFitImages = function() { "use strict";

    function t(t) { for (var e, r = getComputedStyle(t).fontFamily, i = {}; null !== (e = n.exec(r));) i[e[1]] = e[2]; return i }

    function e(e, i) { if (!e[c].parsingSrcset) { var s = t(e); if (s["object-fit"] = s["object-fit"] || "fill", !e[c].s) { if ("fill" === s["object-fit"]) return; if (!e[c].skipTest && l && !s["object-position"]) return } var n = e[c].ios7src || e.currentSrc || e.src; if (i) n = i;
            else if (e.srcset && !a && window.picturefill) { var o = window.picturefill._.ns;
                e[c].parsingSrcset = !0, e[o] && e[o].evaled || window.picturefill._.fillImg(e, { reselect: !0 }), e[o].curSrc || (e[o].supported = !1, window.picturefill._.fillImg(e, { reselect: !0 })), delete e[c].parsingSrcset, n = e[o].curSrc || n } if (e[c].s) e[c].s = n, i && (e[c].srcAttr = i);
            else { e[c] = { s: n, srcAttr: i || f.call(e, "src"), srcsetAttr: e.srcset }, e.src = c; try { e.srcset && (e.srcset = "", Object.defineProperty(e, "srcset", { value: e[c].srcsetAttr })), r(e) } catch (t) { e[c].ios7src = n } }
            e.style.backgroundImage = 'url("' + n + '")', e.style.backgroundPosition = s["object-position"] || "center", e.style.backgroundRepeat = "no-repeat", /scale-down/.test(s["object-fit"]) ? (e[c].i || (e[c].i = new Image, e[c].i.src = n), function t() { return e[c].i.naturalWidth ? void(e[c].i.naturalWidth > e.width || e[c].i.naturalHeight > e.height ? e.style.backgroundSize = "contain" : e.style.backgroundSize = "auto") : void setTimeout(t, 100) }()) : e.style.backgroundSize = s["object-fit"].replace("none", "auto").replace("fill", "100% 100%") } }

    function r(t) { var r = { get: function() { return t[c].s }, set: function(r) { return delete t[c].i, e(t, r), r } };
        Object.defineProperty(t, "src", r), Object.defineProperty(t, "currentSrc", { get: r.get }) }

    function i() { u || (HTMLImageElement.prototype.getAttribute = function(t) { return !this[c] || "src" !== t && "srcset" !== t ? f.call(this, t) : this[c][t + "Attr"] }, HTMLImageElement.prototype.setAttribute = function(t, e) {!this[c] || "src" !== t && "srcset" !== t ? g.call(this, t, e) : this["src" === t ? "src" : t + "Attr"] = String(e) }) }

    function s(t, r) { var i = !A && !t; if (r = r || {}, t = t || "img", u && !r.skipTest) return !1; "string" == typeof t ? t = document.querySelectorAll("img") : t.length || (t = [t]); for (var n = 0; n < t.length; n++) t[n][c] = t[n][c] || r, e(t[n]);
        i && (document.body.addEventListener("load", function(t) { "IMG" === t.target.tagName && s(t.target, { skipTest: r.skipTest }) }, !0), A = !0, t = "img"), r.watchMQ && window.addEventListener("resize", s.bind(null, t, { skipTest: r.skipTest })) } var c = "data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==",
        n = /(object-fit|object-position)\s*:\s*([-\w\s%]+)/g,
        o = new Image,
        l = "object-fit" in o.style,
        u = "object-position" in o.style,
        a = "string" == typeof o.currentSrc,
        f = o.getAttribute,
        g = o.setAttribute,
        A = !1; return s.supportsObjectFit = l, s.supportsObjectPosition = u, i(), s }();

/*! lazysizes - v3.0.0-rc3 */
! function(a, b) { var c = b(a, a.document);
    a.lazySizes = c, "object" == typeof module && module.exports ? module.exports = c : "function" == typeof define && define.amd && define(c) }(window, function(a, b) { "use strict"; if (b.getElementsByClassName) { var c, d = b.documentElement,
            e = a.Date,
            f = a.HTMLPictureElement,
            g = "addEventListener",
            h = "getAttribute",
            i = a[g],
            j = a.setTimeout,
            k = a.requestAnimationFrame || j,
            l = a.requestIdleCallback,
            m = /^picture$/i,
            n = ["load", "error", "lazyincluded", "_lazyloaded"],
            o = {},
            p = Array.prototype.forEach,
            q = function(a, b) { return o[b] || (o[b] = new RegExp("(\\s|^)" + b + "(\\s|$)")), o[b].test(a[h]("class") || "") && o[b] },
            r = function(a, b) { q(a, b) || a.setAttribute("class", (a[h]("class") || "").trim() + " " + b) },
            s = function(a, b) { var c;
                (c = q(a, b)) && a.setAttribute("class", (a[h]("class") || "").replace(c, " ")) },
            t = function(a, b, c) { var d = c ? g : "removeEventListener";
                c && t(a, b), n.forEach(function(c) { a[d](c, b) }) },
            u = function(a, c, d, e, f) { var g = b.createEvent("CustomEvent"); return g.initCustomEvent(c, !e, !f, d || {}), a.dispatchEvent(g), g },
            v = function(b, d) { var e;!f && (e = a.picturefill || c.pf) ? e({ reevaluate: !0, elements: [b] }) : d && d.src && (b.src = d.src) },
            w = function(a, b) { return (getComputedStyle(a, null) || {})[b] },
            x = function(a, b, d) { for (d = d || a.offsetWidth; d < c.minSize && b && !a._lazysizesWidth;) d = b.offsetWidth, b = b.parentNode; return d },
            y = function() { var a, c, d = [],
                    e = [],
                    f = d,
                    g = function() { var b = f; for (f = d.length ? e : d, a = !0, c = !1; b.length;) b.shift()();
                        a = !1 },
                    h = function(d, e) { a && !e ? d.apply(this, arguments) : (f.push(d), c || (c = !0, (b.hidden ? j : k)(g))) }; return h._lsFlush = g, h }(),
            z = function(a, b) { return b ? function() { y(a) } : function() { var b = this,
                        c = arguments;
                    y(function() { a.apply(b, c) }) } },
            A = function(a) { var b, c = 0,
                    d = 125,
                    f = 666,
                    g = f,
                    h = function() { b = !1, c = e.now(), a() },
                    i = l ? function() { l(h, { timeout: g }), g !== f && (g = f) } : z(function() { j(h) }, !0); return function(a) { var f;
                    (a = a === !0) && (g = 44), b || (b = !0, f = d - (e.now() - c), 0 > f && (f = 0), a || 9 > f && l ? i() : j(i, f)) } },
            B = function(a) { var b, c, d = 99,
                    f = function() { b = null, a() },
                    g = function() { var a = e.now() - c;
                        d > a ? j(g, d - a) : (l || f)(f) }; return function() { c = e.now(), b || (b = j(g, d)) } },
            C = function() { var f, k, l, n, o, x, C, E, F, G, H, I, J, K, L, M = /^img$/i,
                    N = /^iframe$/i,
                    O = "onscroll" in a && !/glebot/.test(navigator.userAgent),
                    P = 0,
                    Q = 0,
                    R = 0,
                    S = -1,
                    T = function(a) { R--, a && a.target && t(a.target, T), (!a || 0 > R || !a.target) && (R = 0) },
                    U = function(a, c) { var e, f = a,
                            g = "hidden" == w(b.body, "visibility") || "hidden" != w(a, "visibility"); for (F -= c, I += c, G -= c, H += c; g && (f = f.offsetParent) && f != b.body && f != d;) g = (w(f, "opacity") || 1) > 0, g && "visible" != w(f, "overflow") && (e = f.getBoundingClientRect(), g = H > e.left && G < e.right && I > e.top - 1 && F < e.bottom + 1); return g },
                    V = function() { var a, e, g, i, j, m, n, p, q; if ((o = c.loadMode) && 8 > R && (a = f.length)) { e = 0, S++, null == K && ("expand" in c || (c.expand = d.clientHeight > 500 && d.clientWidth > 500 ? 500 : 370), J = c.expand, K = J * c.expFactor), K > Q && 1 > R && S > 2 && o > 2 && !b.hidden ? (Q = K, S = 0) : Q = o > 1 && S > 1 && 6 > R ? J : P; for (; a > e; e++)
                                if (f[e] && !f[e]._lazyRace)
                                    if (O)
                                        if ((p = f[e][h]("data-expand")) && (m = 1 * p) || (m = Q), q !== m && (C = innerWidth + m * L, E = innerHeight + m, n = -1 * m, q = m), g = f[e].getBoundingClientRect(), (I = g.bottom) >= n && (F = g.top) <= E && (H = g.right) >= n * L && (G = g.left) <= C && (I || H || G || F) && (l && 3 > R && !p && (3 > o || 4 > S) || U(f[e], m))) { if (ba(f[e]), j = !0, R > 9) break } else !j && l && !i && 4 > R && 4 > S && o > 2 && (k[0] || c.preloadAfterLoad) && (k[0] || !p && (I || H || G || F || "auto" != f[e][h](c.sizesAttr))) && (i = k[0] || f[e]);
                            else ba(f[e]);
                            i && !j && ba(i) } },
                    W = A(V),
                    X = function(a) { r(a.target, c.loadedClass), s(a.target, c.loadingClass), t(a.target, Z) },
                    Y = z(X),
                    Z = function(a) { Y({ target: a.target }) },
                    $ = function(a, b) { try { a.contentWindow.location.replace(b) } catch (c) { a.src = b } },
                    _ = function(a) { var b, d, e = a[h](c.srcsetAttr);
                        (b = c.customMedia[a[h]("data-media") || a[h]("media")]) && a.setAttribute("media", b), e && a.setAttribute("srcset", e), b && (d = a.parentNode, d.insertBefore(a.cloneNode(), a), d.removeChild(a)) },
                    aa = z(function(a, b, d, e, f) { var g, i, k, l, o, q;
                        (o = u(a, "lazybeforeunveil", b)).defaultPrevented || (e && (d ? r(a, c.autosizesClass) : a.setAttribute("sizes", e)), i = a[h](c.srcsetAttr), g = a[h](c.srcAttr), f && (k = a.parentNode, l = k && m.test(k.nodeName || "")), q = b.firesLoad || "src" in a && (i || g || l), o = { target: a }, q && (t(a, T, !0), clearTimeout(n), n = j(T, 2500), r(a, c.loadingClass), t(a, Z, !0)), l && p.call(k.getElementsByTagName("source"), _), i ? a.setAttribute("srcset", i) : g && !l && (N.test(a.nodeName) ? $(a, g) : a.src = g), (i || l) && v(a, { src: g })), a._lazyRace && delete a._lazyRace, s(a, c.lazyClass), y(function() {
                            (!q || a.complete && a.naturalWidth > 1) && (q ? T(o) : R--, X(o)) }, !0) }),
                    ba = function(a) { var b, d = M.test(a.nodeName),
                            e = d && (a[h](c.sizesAttr) || a[h]("sizes")),
                            f = "auto" == e;
                        (!f && l || !d || !a.src && !a.srcset || a.complete || q(a, c.errorClass)) && (b = u(a, "lazyunveilread").detail, f && D.updateElem(a, !0, a.offsetWidth), a._lazyRace = !0, R++, aa(a, b, f, e, d)) },
                    ca = function() { if (!l) { if (e.now() - x < 999) return void j(ca, 999); var a = B(function() { c.loadMode = 3, W() });
                            l = !0, c.loadMode = 3, W(), i("scroll", function() { 3 == c.loadMode && (c.loadMode = 2), a() }, !0) } }; return { _: function() { x = e.now(), f = b.getElementsByClassName(c.lazyClass), k = b.getElementsByClassName(c.lazyClass + " " + c.preloadClass), L = c.hFac, i("scroll", W, !0), i("resize", W, !0), a.MutationObserver ? new MutationObserver(W).observe(d, { childList: !0, subtree: !0, attributes: !0 }) : (d[g]("DOMNodeInserted", W, !0), d[g]("DOMAttrModified", W, !0), setInterval(W, 999)), i("hashchange", W, !0), ["focus", "mouseover", "click", "load", "transitionend", "animationend", "webkitAnimationEnd"].forEach(function(a) { b[g](a, W, !0) }), /d$|^c/.test(b.readyState) ? ca() : (i("load", ca), b[g]("DOMContentLoaded", W), j(ca, 2e4)), f.length ? (V(), y._lsFlush()) : W() }, checkElems: W, unveil: ba } }(),
            D = function() { var a, d = z(function(a, b, c, d) { var e, f, g; if (a._lazysizesWidth = d, d += "px", a.setAttribute("sizes", d), m.test(b.nodeName || ""))
                            for (e = b.getElementsByTagName("source"), f = 0, g = e.length; g > f; f++) e[f].setAttribute("sizes", d);
                        c.detail.dataAttr || v(a, c.detail) }),
                    e = function(a, b, c) { var e, f = a.parentNode;
                        f && (c = x(a, f, c), e = u(a, "lazybeforesizes", { width: c, dataAttr: !!b }), e.defaultPrevented || (c = e.detail.width, c && c !== a._lazysizesWidth && d(a, f, e, c))) },
                    f = function() { var b, c = a.length; if (c)
                            for (b = 0; c > b; b++) e(a[b]) },
                    g = B(f); return { _: function() { a = b.getElementsByClassName(c.autosizesClass), i("resize", g) }, checkElems: g, updateElem: e } }(),
            E = function() { E.i || (E.i = !0, D._(), C._()) }; return function() { var b, d = { lazyClass: "lazyload", loadedClass: "lazyloaded", loadingClass: "lazyloading", preloadClass: "lazypreload", errorClass: "lazyerror", autosizesClass: "lazyautosizes", srcAttr: "data-src", srcsetAttr: "data-srcset", sizesAttr: "data-sizes", minSize: 40, customMedia: {}, init: !0, expFactor: 1.5, hFac: .8, loadMode: 2 };
            c = a.lazySizesConfig || a.lazysizesConfig || {}; for (b in d) b in c || (c[b] = d[b]);
            a.lazySizesConfig = c, j(function() { c.init && E() }) }(), { cfg: c, autoSizer: D, loader: C, init: E, uP: v, aC: r, rC: s, hC: q, fire: u, gW: x, rAF: y } } });
// remap jQuery to $

(function($) {
    $('.menu-link').click(function(e) {
        e.preventDefault();
        $('body').toggleClass('open');
        $(this).toggleClass("on");
    });
    $('.search').click(function(e) {
        e.preventDefault();
        $("#search").fadeIn('fast');
        $("body").addClass("page-block");
    });
    $('.close-btn').click(function(e) {
        e.preventDefault();
        $("#search").fadeOut('fast');
        $("body").removeClass("page-block");
    });
    $('#search-input').data('holder', $('#search-input').attr('placeholder'));

    $('#search-input').focusin(function() {
        $(this).attr('placeholder', '');
    });
    $('#search-input').focusout(function() {
        $(this).attr('placeholder', $(this).data('holder'));
    });

    $('.search').click(function() {
        $('#search-input').focus();
    });
    $('.swatches .tab-content .tab-pane:first-child').addClass('active');
    $('.swatches .nav-tabs li:first-child').addClass('active');
    $("p,h1,h2,h3,h4,li,a").each(function() {
        $(this).html($(this).html().replace(/&reg;/gi, '<sup>&reg;</sup>').replace(/®/gi, '<sup>&reg;</sup>'));
    });

    // TEST FOR SHARPSPRING CUSTOM MAPPING AJAX CALL
    // var sharpspringData = {
    //   "mapping": "pocVote2019",
    //   "data": [
    //     {
    //       "email": 'mvaski@gmail.com',
    //     }
    //   ],
    //   "CRAFT_CSRF_TOKEN": $('input[name="CRAFT_CSRF_TOKEN"]').val()
    // };
    // sharpspringData["data"][0]["firstname"] = 'Matthew';
    // sharpspringData["data"][0]["lastname"] = 'Vasilevski';
    // sharpspringData["data"][0]["receiveOffers"] = true;
    // sharpspringData["data"][0]["isVoter"] = true;
    // console.log(sharpspringData);
    // $.ajax({
    //   url: '/actions/sharpspring-integration/integration/push-async',
    //   type: 'POST',
    //   contentType: 'application/json',
    //   data: JSON.stringify(sharpspringData),
    //   dataType: 'json',
    //   timeout: 12000,
    // })
    // .done(function(data) {
    //   console.log('success');
    // })
    // .fail(function(data) {
    //   console.log("There was an error updating data to the CRM. Please see logs for details.");
    // });
})(jQuery);
